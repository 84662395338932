import React from 'react'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'
import colors from '../utils/colors'

const styles = {
  link: {
    color: 'inherit',
    textDecoration: 'none !important',
  },
  review: {
    backgroundColor: colors.lightGrey,
    borderRadius: '6px',
    marginBottom: rhythm(1.5),
    padding: rhythm(0.75),
    width: '100%',
  },
  reviewTitle: {
    color: colors.blue,
    fontWeight: 'bold',
    marginTop: rhythm(0.75),
    textAlign: 'right',
  }
}

export const Review = ({ title, excerpt, html, slug }) => {
  const internals = (
    <div css={styles.review}>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {excerpt}
      <div css={styles.reviewTitle}>
        {title}
      </div>
    </div>
  )
  if (slug) {
    return (
      <Link to={slug} css={styles.link}>
        {internals}
      </Link>
    )
  }
  return internals
}

export default Review