import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Review from '../components/Review'
import Share from '../components/Share'

import { themeStyles } from '../utils/theme'

class Reviews extends React.Component {
  render() {
    let posts = get(this, 'props.data.allMarkdownRemark.edges') || []
    posts = posts.filter(post => post.node.frontmatter.published)
    const postElements = posts.map(({ node }) => {
      const title = get(node, 'frontmatter.title')
      return <Review key={node.id} slug={node.fields.slug} title={title} excerpt={node.excerpt} />
    })
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Testimonials"
          keywords={keywords}
        />
        <div css={themeStyles.textPadding}>
          <h2>Testimonials</h2>
          {postElements}
        </div>
        <Share slug="/reviews" title={siteTitle} excerpt={siteDescription} />
      </Layout>
    )
  }
}

export default Reviews

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allMarkdownRemark(filter: {fields: {slug: { regex: "/reviews/"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            published
          }
        }
      }
    }
  }
`